import React from 'react'
import './galerie.scss'

import gespraechsecke from '../../assets/images/gesprächsecke.jpg'
import behandlungsbereich from '../../assets/images/behandlungsbereich.jpg'
import behandlung from '../../assets/images/image-480x480.jpg'

const Galerie = () => {

  return (
    <section id='galerie' className='galerie'>
      <div className="container pt-5 pb-5">
        <div className="row text-center">
          <div className="col-lg-12">
            <h1 className="display-1">Galerie</h1><hr />
          </div>
          <div className="col-lg-4">
            <h2>Gesprächsecke</h2>
            <img className='img-fluid rounded-4' src={gespraechsecke} alt="Gesprächsecke Bild" />
          </div>
          <div className="col-lg-4">
            <h2>Bechandlungsbereich</h2>
            <img className='img-fluid rounded-4' src={behandlungsbereich} alt="Behandlungsbereich Bild" />
          </div>
          <div className="col-lg-4">
            <h2>Bechandlung</h2>
            <img className='img-fluid rounded-4' src={behandlung} alt="Behandlung Bild" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Galerie
